import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    subscription: Subscription;

    constructor(public userblockService: UserblockService, private settings:SettingsService) {

        this.user = {
            picture: settings.getUserSetting('picture'),
            name: settings.getUserSetting('name'),
            job: settings.getUserSetting('job')
        };
    }

    ngOnInit() {
        this.subscription = this.settings.subscribeCurrentUser((c) => {
            this.user = c;
        });
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
