import {
    Component, Input, ViewChild, OnChanges
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

declare var jQuery:any;
declare var $:any;

@Component({
    selector: 'pacman-loader',            
    templateUrl: './pacman.component.html',
    styleUrls: ['./pacman.component.scss'],  
    host: { '[class]': 'class' }
})
export class PacmanLoader {
}
