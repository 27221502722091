import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { SettingsService } from '../../../core/settings/settings.service';

import { RoutesService } from '../../routes.service';
import { User } from '../../user/user';
import * as Global from '../../../global';
import { HttpClient } from '@angular/common/http';

@Component({
    moduleId: module.id,
    selector: 'app-nova-senha',
    templateUrl: './nova-senha.component.html',
    styleUrls: ['./nova-senha.component.scss']
})

export class NovaSenhaComponent implements OnInit {

    valForm: FormGroup;
    emailHash: string;
    idColaborador: number;
    idRecuperacao: number;

    public user: User = new User();

    public showError = {
        actived: false,
        message: ""
    }

    public showSucess = {
        actived: false,
        message: ""
    }

    // tslint:disable-next-line:max-line-length
    constructor(private http: HttpClient, public settings: SettingsService, fb: FormBuilder, private route: Router, private service: RoutesService, private routeActived: ActivatedRoute) {
        this.valForm = fb.group({
            'senha': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
            'senhaConfirmacao': [null, Validators.compose([Validators.required, Validators.minLength(6)])]
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.service.updateSenhaUsuario(this.user, this.valForm.controls['senha'].value, this.idRecuperacao,
                (data) => {
                    this.showSucess = {
                        actived: true,
                        message: "Senha alterada com sucesso!"
                    };
                }, (error) => {
                    if (error.status == "406") {
                        this.showError = {
                            actived: true,
                            message: "O tempo da sua solicitação de nova senha expirou, por favor peça uma nova solicitação de senha."
                        };
                    } else {
                        this.showError = {
                            actived: true,
                            message: "Ocorreu um erro ao atualizar sua senha, por favor tente novamente."
                        };
                    }
                    console.log(error)
                });
        }
    }

    private _carregaUsuario(): void {
        //Carrrega o usuario da base atraves do ID enviado no parametro
        this.routeActived.params.subscribe((params: Params) => {
            this.emailHash = params['emailHash'];
            this.idColaborador = params['idColaborador'];
            this.idRecuperacao = params['idRecuperacao'];
        })

        if (this.emailHash != "" && this.idColaborador > 0) {
            this.http.get<User>(Global.DATA_SERVER + `colaboradorrepository/${this.idColaborador}`)
                .subscribe((data) => {
                    this.user = data;
                },
                (error) => {
                    alert(JSON.stringify(error));
                    this.showError = {
                        actived: true,
                        message: "Erro ao recuperar token do usuário!"
                    };
                });
        }
    }

    ngOnInit() {
        this._carregaUsuario();
    }
}