import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
// HttpHeaders, RequestOptions, ResponseContentType
import { CookieService } from './cookie.service';
import { User } from './user/user';
import { Permissao } from '../perfil/permissao';
import { menu } from './menu';

import * as Global from '../global';
import { DATA_SERVER } from './../global';

const activityKey = 'CP_USER_ACTIVITY_CODE';
const userKey = 'CP_USER_CODE';
const userSplitter = ';##@_@*;';

@Injectable()
export class RoutesService {

    private alreadyCheckStarted = false;
    private validLogin = new Observable<boolean>(this.validateLogin);

    constructor(private http: HttpClient, private cookies: CookieService, private routes: Router) { }

    public getAuthToken(): string {
      return this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);
    }

    public getApiPath(): string {
        return DATA_SERVER;
    }

    public getFileFromService(servicePath: string, successCallback, errorCallback?): void {
        const method = '[getFileFromService] ';

            console.log(method + 'starting get request to: ' + servicePath);

            const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);
            const headers = new HttpHeaders()
                .set('Authorization', authCookie);
            const options = {
                headers,
                responseType: 'blob' as 'blob'
            };

            this.http.get(Global.DATA_SERVER + servicePath, options)
                .subscribe((data) => {
                    if (successCallback) {

                        console.log(method + 'success callback present, running');
                        successCallback(data);
                    }
                },
                (error) => {

                    console.log(method + 'error on get');
                    if (errorCallback) {

                        console.log(method + 'calling error callback');
                        errorCallback(error);
                    }
                });
    }

    public getDataFromService(servicePath: string, successCallback, errorCallback?): void {
        const method = '[getDataFromService] ';

            console.log(method + 'starting get request to: ' + servicePath);

            const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);
            const headers = new HttpHeaders()
                .set('Authorization', authCookie);

            this.http.get(Global.DATA_SERVER + servicePath, {headers})
                .subscribe((data) => {
                    if (successCallback) {

                        console.log(method + 'success callback present, running');
                        successCallback(data);
                    }
                },
                (error) => {

                    console.log(method + 'error on get');
                    if (errorCallback) {

                        console.log(method + 'calling error callback');
                        errorCallback(error);
                    }
                });
    }

    // tslint:disable-next-line:max-line-length
    public postDataToService(servicePath: string, bodyData: object, successCallback, errorCallback?, additionalHeaders?: HttpHeaders): void {
        const method = '[postDataToService] ';

        let contentTypeAlreadySet = false;

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);
        let headers = new HttpHeaders()
            .set('Authorization', authCookie);

        if (!!additionalHeaders) {
            additionalHeaders.keys().forEach((key) => {
                console.log(method + 'adding provided header: ' + key + ', ' + additionalHeaders.getAll(key));

                headers = headers.set(key, additionalHeaders.getAll(key));

                if (key.toLocaleLowerCase() === 'content-type') {
                    contentTypeAlreadySet = true;
                }
            });
        }

        if (!contentTypeAlreadySet) {
            headers = headers.set('Content-Type', 'application/json');
        }

        console.log(method + 'starting post request to: ' + servicePath);

        this.http.post(Global.DATA_SERVER + servicePath, bodyData, {headers})
            .subscribe((data) => {
                console.log(method + ' response: ' + JSON.stringify(data));
                if (successCallback) {

                    console.log(method + 'success callback present, running');
                    successCallback(data);
                }
            },
            (error) => {

                console.log(method + 'error on post');
                if (errorCallback) {

                    console.log(method + 'calling error callback');
                    errorCallback(error);
                }
            });
    }

    // tslint:disable-next-line:max-line-length
    public putDataIntoService(servicePath: string, bodyData: object, successCallback, errorCallback?, additionalHeaders?: HttpHeaders): void {
        const method = '[putDataIntoService] ';
        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

            let headers = new HttpHeaders()
                .set('Authorization', authCookie)
                .set('Content-Type', 'application/json');

            if (!!additionalHeaders) {
                additionalHeaders.keys().forEach((key) => {
                    console.log(method + 'adding provided header: ' + key + ', ' + additionalHeaders.getAll(key));

                    headers = headers.set(key, additionalHeaders.getAll(key));
                });
            }

            console.log(method + 'starting post request to: ' + servicePath);
            console.log('PATH: ' + Global.DATA_SERVER + servicePath);

            this.http.put(Global.DATA_SERVER + servicePath, bodyData, {headers})
                .subscribe((data) => {
                    if (successCallback) {

                        console.log(method + 'success callback present, running');
                        successCallback(data);
                    }
                },
                (error) => {

                    console.log(method + 'error on put');
                    if (errorCallback) {

                        console.log(method + 'calling error callback');
                        errorCallback(error);
                    }
                });
    }

    public deleteDataFromService(servicePath: string, successCallback, errorCallback?, additionalHeaders?: HttpHeaders): void {
        const method = '[deleteDataFromService] ';
        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

            let headers = new HttpHeaders()
                .set('Authorization', authCookie)
                .set('Content-Type', 'application/json');

            if (!!additionalHeaders) {
                additionalHeaders.keys().forEach((key) => {
                    console.log(method + 'adding provided header: ' + key + ', ' + additionalHeaders.getAll(key));

                    headers = headers.set(key, additionalHeaders.getAll(key));
                });
            }

            console.log(method + 'starting post request to: ' + servicePath);
            console.log('PATH: ' + Global.DATA_SERVER + servicePath);

            this.http.delete(Global.DATA_SERVER + servicePath, {headers})
                .subscribe((data) => {
                    if (successCallback) {

                        console.log(method + 'success callback present, running');
                        successCallback(data);
                    }
                },
                (error) => {

                    console.log(method + 'error on put');
                    if (errorCallback) {

                        console.log(method + 'calling error callback');
                        errorCallback(error);
                    }
                });
    }

    // *** Métodos Login ***
    public loginUser(user: string, pass: string, successCallback, errorCallback?): void {
        this.loginBackground(user, pass,
            () => {
                if (successCallback) {
                    successCallback(user);
                }
            },
            () => {
                if (errorCallback) {
                    errorCallback('Usuario ou Senha inválidos');
                }
            });
    }

    public enviarEmailRecuperarSenha(email: Object, successCallback, errorCallback?): void {

        const headers = new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set('X-Origin', window.location.origin);

        this.http.post(Global.DATA_SERVER + 'colaboradorrepository/recuperar-senha', email, {headers})
            .subscribe(
                (data) => {
                    if (successCallback) {
                        successCallback(data);
                    }
                }, (err) => {
                    if (errorCallback) {
                        errorCallback(err);
                    }
                }
            );
    }

    public getUsuario(idUsuario: number, successCallback, errorCallback?) {
        if (idUsuario > 0) {
            this.getDataFromService('colaboradorrepository/' + idUsuario,
                (data) => {
                    const entity: User = data;
                    if (successCallback) {
                        successCallback(entity);
                    }
                },
                (err) => {
                    if (errorCallback) {
                        errorCallback(err);
                    }
                });
        }
    }

    public getUsuarioByEmail(email: string, successCallback, errorCallback?) {
        if (email) {
            this.getDataFromService('colaboradorrepository/login?email=' + email,
                (data) => {
                    const entity: User = data;
                    if (successCallback) {
                        successCallback(entity);
                    }
                },
                (err) => {
                    if (errorCallback) {
                        errorCallback(err);
                    }
                });
        }
    }

    public updateSenhaUsuario(user: User, senha: string, idRecuperacao: number, successCallback, errorCallback?) {
        if (!!senha && !!user) {

            const userChanged = {
                login: user.email,
                senha: senha
            };

            const headers = new HttpHeaders()
                .set('Content-Type', 'application/json');

            this.http.put(Global.DATA_SERVER + 'colaboradorrepository/atualizar-senha/' + idRecuperacao, userChanged, {headers})
            .subscribe(
                (data) => {
                    if (successCallback) {
                        successCallback(data);
                    }
                }, (error) => {
                    if (errorCallback) {
                        errorCallback(error);
                    }
                });
        }
    }

    public logOut() {

        this.cookies.setCookie(Global.COOKIE_LOGIN_NAME, '', 1);
        this.cookies.setCookie(Global.COOKIE_PERMS_NAME, '', 1);
        localStorage.removeItem(userKey);
        localStorage.removeItem(activityKey);
        localStorage.removeItem(Global.USER_KEY);

        this.routes.navigateByUrl('/login');
    }

    public getLoggedUsername(): string {

        return atob(localStorage.getItem(Global.USER_KEY));
    }

    private loginBackground(user: string, pass: string, success, failure?) {

        const dados = {
            username: user,
            password: pass
        };

        const method = '[loginUser] ';

        const headers = new HttpHeaders()
                .set('Content-Type', 'application/json');

        this.http.post<Object>(Global.DATA_SERVER + 'autenticacaoadmin/login', dados, {headers, observe: 'response'})
            .subscribe((data: HttpResponse<any>) => {

                const headerValues: HttpHeaders = data.headers;
                let devMode = false;

                // ao utilizar com CORS o método "has" não funciona, porém o get sim (-_-)
                if (Global.DATA_SERVER.startsWith('http://')) {
                    devMode = true;
                }
                if (headerValues.has('Authorization') || devMode) {
                    console.dir(headerValues.get('Authorization'));

                    this.cookies.setCookie(Global.COOKIE_LOGIN_NAME, headerValues.get('Authorization'), 60);
                    localStorage.setItem(Global.USER_KEY, btoa(user));

                    const userValue = user + userSplitter + pass;
                    localStorage.setItem(userKey, btoa(userValue));

                    console.log(method + 'login complete with cookie set');
                    this.setCookiePermissoesUsuarioLogado();

                    if (success) {
                        success(user);
                    }
                } else {
                    if (failure) {
                        failure(user);
                    }
                }
            },
            (error) => {
                console.log(method + 'Error: ' + error);
                if (failure) {
                    failure(user);
                }
            });
    }

    private setCookiePermissoesUsuarioLogado() {

        this.getDataFromService('colaboradorrepository/logado/permissoes',
            (data) => {
                this.cookies.setCookie(Global.COOKIE_PERMS_NAME, btoa(JSON.stringify(data)), 60);
            }, (err) => {
                console.error('NÃO FOI POSSÍVEL RECUPERAR AS PERMISSOES DO USUÁRIO!');
                this.logOut();
            });
    }

    public getPermissoesUsuarioLogado(): Array<Permissao> {
        const permissoes = this.cookies.getCookie(Global.COOKIE_PERMS_NAME);
        if ( !permissoes ) {
            return new Array<Permissao>();
        }
        return JSON.parse(atob(permissoes));
    }

    public verificaUsuarioPossuiPermissaoPagina(permissaoPagina: string) {
        console.log('Checando permissao ' + permissaoPagina + ' para o usuario logado');
        const permissoes = this.getPermissoesUsuarioLogado();

        const permissaoExiste = permissoes.find(p => p.permissao === permissaoPagina );
        if ( permissaoExiste === undefined || permissaoExiste == null ) {

            if ( permissoes === [] || permissoes.length === 0 ) {
                this.routes.navigateByUrl('login');
                return ;
            }

            // Envia para a primeira pagina das permissoes dele
            const itemMenu: any = menu.find( item => item.permissao === permissoes[0].permissao );

            if (itemMenu.submenu != null && itemMenu.submenu !== undefined ) {
                this.routes.navigateByUrl(itemMenu.submenu[0].link);
            } else {
                this.routes.navigateByUrl(itemMenu.link);
            }
        }
    }

    public verificaTemPermissaoEscrita(permissaoPagina: string): boolean {
        const permissoes = this.getPermissoesUsuarioLogado();

        const permissaoExiste = permissoes.find(p => p.permissao === permissaoPagina );

        if ( !permissaoExiste ) { return false; }

        return ( permissaoExiste.tipo === 'ESCRITA' );
    }

    private validateLogin(observer) {

        const method = '[checkLogin] ';
        console.log(method + 'called with started: ' + this.alreadyCheckStarted);


        this.alreadyCheckStarted = true;

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        if (authCookie != null && authCookie.length > 0) {
            // a chave ainda é válida
            this.registerActivity();
            observer.next(true);
        } else {

            console.log(method + 'cookie not available');
            if (this.lastActivityOffset() > (60 * 60 * 1000)) {
                // 1 hora sem atividade
                console.log(method + 'user is inactive and logged out');
                this.routes.navigateByUrl('/login');
                observer.next(false);
            } else {
                // tentativa de logar
                const userData = localStorage.getItem(userKey);

                if (userData != null && userData.length > 0) {

                    console.log(method + 'requesting new login token');
                    const values = atob(userData).split(userSplitter);
                    const username = values[0];
                    const userpass = values[1];

                    // ao completar com sucesso o cookie é armazenado caso contrário o login existente não é mais válido
                    this.loginBackground(username, userpass, () => {
                        console.log(method + 'new token OK');
                        observer.next(true);
                    }, () => {
                        this.routes.navigateByUrl('/login');
                        observer.next(false);
                    });
                } else {
                    this.routes.navigateByUrl('/login');
                    observer.next(false);
                }
            }
        }
    }

    private registerActivity() {
        localStorage.setItem(activityKey, btoa('' + new Date().getTime()));
    }

    private lastActivityOffset(): number {

        let v = localStorage.getItem(activityKey);
        if (v != null && v.length > 0) {
            v = atob(v);
        } else {
            v = '0';
        }

        return new Date().getTime() - parseInt(v);
    }
}
