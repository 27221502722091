
const Home = {
    text: 'Home',
    translate: 'general.HOME',
    link: '/home',
    icon: 'fa fa-home',
    permissao: 'HOME'
};

const headingMain = {
    text: 'Main Navigation',
    translate: 'dashboard.WELCOME',
    heading: true
};

const usersMenu = {
    text: 'Admin Usuários',
    translate: 'users.list.TITLE',
    icon: 'fa fa-users',
    permissao: 'USUARIOS',
    submenu : [{
        text: 'Usuários',
        link: '/user/list'
        },
        {
            text: 'Perfis',
            link: '/perfil/lista'
        }
    ]

};

const loginCinemaMenu = {
    text: 'Adm Cinemas',
    link: '/admcinema/lista',
    icon: 'fa fa-video-camera',
    permissao: "ADMIN_CINEMAS",
}


const admRI = {
    text: 'RI',
    link: '/admri/lista',
    icon: 'fa fa-bar-chart',
    permissao: "ADMIN_RI",
    submenu: [
        {
            text: 'Arquivos',
            link: '/admri/lista'
        },
        {
            text: 'Calendário',
            link: '/admri/calendario/lista'
        }
    ]
};

const concursoCulturalMenu = {
    text: 'Concursos',
    translate: 'concursoCultural.TITLE_MENU',
    link: '/concursos/lista',
    icon: 'fa fa-gift',
    permissao: "CONCURSOS_CULTURAIS"
};

const clientesMenu = {
    text: 'Clientes',
    translate: 'clientes.lista.TITLE',
    icon: 'fa fa-child',
    permissao: "CLIENTES",
    submenu: [
        {
            text: 'Lista de Clientes',
            link: '/clientes/lista',
        },
        {
            text: 'Cartão VIP',
            link: '/clientes/vip/lista',
        },
        {
            text: 'Meia Entrada',
            link: '/clientes/meiaentrada/lista',
        },
        {
            text: 'Categoria de Clientes',
            link: '/clientes/categoria/lista',
        },
        {
            text: 'Importar Clientes',
            link: '/clientes/importacao'
        }
    ]
};

const premiosMenu = {
    text: 'Prêmios',
    translate: 'premios.list.TITLE',
    link: '/premios/lista',
    icon: 'fa fa-trophy',
    permissao: "PREMIOS"
}

const preVendaMenu = {
    text: 'Pré-Venda',
    translate: 'pre-venda.list.TITLE',
    link: '/pre-venda/lista',
    icon: 'fa fa-trophy',
    permissao: "PONTUACAO_PRE_VENDA"
}

const produtosMenu = {
    text: 'Produtos',
    translate: 'produtos.lista.TITLE',
    link: '/produtos/lista',
    icon: 'fa fa-shopping-basket',
    permissao: "PRODUTOS",
};

const promocoesMenu = {
    text: 'Promoções',
    translate: 'promocoes.lista.TITLE',
    link: '/promocoes/lista',
    icon: 'fa fa-usd',
    permissao: "PROMOCOES"
};

const conteudoSiteMenu = {
    text: 'Conteúdo Site',
    translate: 'conteudo.general.MENU',
    icon: 'fa fa-star-o',
    permissao: "CONTEUDO_SITE",
    submenu: [
        {
            text: 'Destaques',
            link: '/destaques/lista',
        },
        {
            text: 'Trailers',
            link: '/trailer/'
        }
    ]
};

const relatoriosMenu = {
    text: 'Relatórios',
    translate: 'relatorios.TITLE',
    link: '/relatorios/lista',
    icon: 'fa fa-file-text-o',
    permissao: "RELATORIOS",
    submenu: [
        {
            text: 'Compras e Frequência',
            translate: 'relatorios.comprasfrequencia.TITLE',
            link: '/relatorios/compras-frequencia'
        },
        {
            text: 'Evolução de Cadastro',
            translate: 'relatorios.evolucaocadastro.TITLE',
            link: '/relatorios/evolucao-cadastro'
        },
        {
            text: 'Participação',
            translate: 'relatorios.participacao.TITLE',
            link: '/relatorios/participacao'
        },
        {
            text: 'Prêmios',
            translate: 'relatorios.premios.TITLE',
            link: '/relatorios/premios'
        },
        {
            text: 'Produtos',
            translate: 'relatorios.produtos.TITLE',
            link: '/relatorios/produtos'
        },
        {
            text: 'Canais de Pontuação',
            translate: 'relatorios.canaispontuacao.TITLE',
            link: '/relatorios/canais-pontuacao'
        },
        {
            text: 'Ecommerce',
            translate: 'relatorios.ecommerce.TITLE',
            link: '/relatorios/ecommerce'
        }
    ]
};

const numerosCinemas = {
    text: 'Números Cinemas',
    translate: 'cinemas.numeros.lista.TITLE',
    link: '/cinemas/lista',
    icon: 'fa fa-film',
    permissao: "NUMEROS_CINEMAS"
};

// Ordem é igual a descrita abaixo
export const menu = [
    Home,
    relatoriosMenu,
    numerosCinemas,
    clientesMenu,
    premiosMenu,
    produtosMenu,
    preVendaMenu,
    promocoesMenu,
    conteudoSiteMenu,
    loginCinemaMenu,
    concursoCulturalMenu,
    admRI,
    usersMenu
];
