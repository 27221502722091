import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './login/login.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './recuperar-senha/nova-senha/nova-senha.component';

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: './home/home.module#HomeModule' },
            { path: 'user', loadChildren: './user/user.module#UserModule' },
            { path: 'clientes', loadChildren: '../clientes/clientes.module#ClientesModule' },
            { path: 'premios', loadChildren: '../premios/premios.module#PremiosModule' },
            { path: 'pre-venda', loadChildren: '../pre-venda/pre-venda.module#PreVendaModule' },
            { path: 'produtos', loadChildren: '../produtos/produtos.module#ProdutosModule' },
            { path: 'admcinema', loadChildren: '../cinemas-login/cinemas-login.module#CinemasLoginModule' },
            { path: 'admri', loadChildren: '../admri/admri.module#AdmriModule' },
            { path: 'promocoes', loadChildren: '../promocoes/promocoes.module#PromocoesModule' },
            { path: 'destaques', loadChildren: '../destaques/destaques.module#DestaquesModule' },
            { path: 'cinemas', loadChildren: '../cinemas/cinemas.module#CinemasModule' },
            { path: 'relatorios', loadChildren: '../relatorios/relatorios.module#RelatoriosModule' },
            { path: 'concursos', loadChildren: '../concursos/concursos.module#ConcursosModule' },
            { path: 'perfil', loadChildren: '../perfil/perfil.module#PerfilModule' },
            { path: 'trailer', loadChildren: '../trailers/trailer.module#TrailerModule' }
        ]
    },

    { path: 'login', component: LoginComponent },
    { path: 'recuperar-senha', component: RecuperarSenhaComponent },
    { path: 'recuperar-senha/nova-senha/:emailHash/:idColaborador/:idRecuperacao', component: NovaSenhaComponent },

    // Not found
    { path: '**', redirectTo: 'home' }

];
