import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { RoutesService } from "../../routes/routes.service";
import { FiltroMultiplex } from "../../modelo/filtromultiplex";

@Component({
  selector: 'filtro-premio',
  templateUrl: './filtro-premio.component.html',
  styleUrls: ['./filtro-premio.component.scss']
})
export class FiltroPremioComponent implements OnInit, ControlValueAccessor {

  tagPremiosValue;
  tagPremiosOption;
  
  /**
     * @description id atribuido a div pai
     * @name objectId
     * @type {string}
     */
  @Input() public objectId: string = "premio-filtro";

  /**
     * @description função que será chamada ao clique do botão Aplicar
     * @name filtroModel
     * @type {FiltroMultiplex}
     */
  @Input() public filtro;

  changeCallback: (item: FiltroMultiplex) => void;
  touchCallback: () => void;

  constructor(private server: RoutesService) {}

  ngOnInit() {
    this.filtro.premios = []
    this.fillPremios();
  }

  private limparPremios() {
    this.tagPremiosValue = [];
    this.filtro.premios = null;
  }

  public get filtroObj() {
    return this.filtro;
  }

  public writeValue(items) {
    this.filtro = items || { premios : [] };
  }

  public registerOnChange(fn: any) {
    this.changeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.touchCallback = fn;
  }

  filtrarPremios(ev) {
    console.log("[filtrarPremios] val EV: " + ev);
    this.aplicarTags();

    this.fillPremios();
  }

  private fillPremios() {

    let query: string = '';
    
    query += (this.filtro.premios.length >0) ? 'premiosExcluidos=' + this.filtro.premios + '&' : 
            'premiosExcluidos=' + 0 + '&'
    
    query += this.queryTimestamp();

    this.server.getDataFromService('relatorios/premios/listaNomesPremios?' + query,
      (data) => {
        this.limparPremios();

        this.tagPremiosOption = data;
      });
  }

  private queryTimestamp(): string {
    return 'cache=false&timestamp=' + new Date().getTime();
  }

  private tagToValue(tag) {

    return tag.value != null ? tag.value + ',' : '';
  }

  alterarPremios(ev) {
    console.log("[alterarPremios] val EV: " + ev);
    this.aplicarTags();
  }

  private aplicarTags() {
    if (this.tagPremiosValue != null) {

      let cid = '';
      this.tagPremiosValue.forEach(element => {
        cid += this.tagToValue(element);
      });

      if (cid.length > 0) {
        this.filtro.premios = cid.substr(0, cid.length - 1);
      } else {
        this.filtro.premios = null
      }
    } else {
      this.filtro.premios = null
    }
  }
}
