import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CustomValidators } from 'ng2-validation';
import { SettingsService } from '../../core/settings/settings.service';

import { RoutesService } from '../routes.service';

@Component({
    moduleId: module.id,
    selector: 'app-recuperar-senha',
    templateUrl: './recuperar-senha.component.html',
    styleUrls: ['./recuperar-senha.component.scss']
})

export class RecuperarSenhaComponent implements OnInit {

    valForm: FormGroup;
    public showError = {
        actived: false,
        message: ''
    };
    public showSucess = {
        actived: false,
        message: ''
    };

    constructor(public settings: SettingsService, fb: FormBuilder, private route: Router, private service: RoutesService) {
        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        Object.keys(this.valForm.controls).forEach(key => {
            this.valForm.get(key).markAsTouched();
        });
        this.showSucess['actived'] = false;
        this.showError['actived'] = false;

        if (this.valForm.valid) {
            if (value['email'] !== '') {
                console.log(value);
            }
            this.service.enviarEmailRecuperarSenha(value,
                (data) => {
                    this.showSucess = {
                        actived: true,
                        message: `As informações para a alteração de sua senha foram enviadas para: ${value['email']}`
                    };
                }, (error) => {
                    if (error.status === '404') {
                        this.showError = {
                            actived: true,
                            message: 'E-mail não cadastrado.'
                        };
                    } else {
                        this.showError = {
                            actived: true,
                            message: 'Ocorreu um erro inesperado por favor tente novamente!'
                        };
                    }

                });
        }
    }

    ngOnInit() { }
}
