import {
    Component, Input, ViewChild, OnChanges
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

declare var jQuery:any;
declare var $:any;

@Component({
    selector: 'basic-loader',            
    templateUrl: './basic.component.html',
    styleUrls: ['./basic.component.scss'],  
    host: { '[class]': 'class' }
})
export class BasicLoader {
  @Input() message : string = "Carregando...";    
}
