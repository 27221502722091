import { Injectable } from '@angular/core';

import * as Global from '../global';

@Injectable()
export class CookieService {

    public setCookie(cname: string, cvalue: string, expminutes: number): void {
        var expires = '';
        if (expminutes) {
            var d = new Date();
            d.setTime(d.getTime() + (expminutes * 60 * 1000));
            expires = "expires=" + d.toUTCString();
        }
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + Global.COOKIE_DOMAIN_PATH;
    }

    public getCookie(cname): string {

        console.log("attemp to get cookie: " + cname);

        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {

                console.log("found cookie: " + cname);

                return c.substring(name.length, c.length);
            }
        }

        console.log("cookie not present: " + cname);
        return null;
    }
}