import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';
import { DatePickerModule } from "app/components/datepicker/datepicker.module";

import { DashboardComponent } from './dashboard/dashboard.component';
import { PeriodoComponent } from './periodo/periodo.component';
import { LocalidadeComponent } from './localidade/localidade.component';
import { FiltroPremioComponent } from './premio/filtro-premio.component';
import { ProdutoComponent } from './produto/produto.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, TagInputModule, DatePickerModule],
  declarations: [PeriodoComponent, LocalidadeComponent, DashboardComponent, ProdutoComponent, FiltroPremioComponent],
  exports: [PeriodoComponent, LocalidadeComponent, DashboardComponent, ProdutoComponent, FiltroPremioComponent]
})
export class FiltroModule { }
