import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CustomValidators } from 'ng2-validation';
import { SettingsService } from '../../core/settings/settings.service';

import { RoutesService } from '../routes.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    valForm: FormGroup;

    asyncOp: boolean = false;

    public errorMessage: string;

    constructor(public settings: SettingsService, fb: FormBuilder, private route: Router, private service: RoutesService) {

        this.valForm = fb.group({
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'password': [null, Validators.required]
        });

    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        this.asyncOp = true;

        Object.keys(this.valForm.controls).forEach(key => {
            this.valForm.get(key).markAsTouched();
        });

          if (this.valForm.valid) {
            console.log('Form valid');
            this.service.loginUser(this.valForm.controls['email'].value, this.valForm.controls['password'].value,
                (email) => {
                    // LOGIN OK
                    console.log("return is ok");
                    this.service.getUsuarioByEmail(email, 
                    (user) => {
                        this.settings.user = {
                            name: user.nome,
                            job: user.funcao + ' - ' + user.departamento,
                            picture: 'assets/img/user/default.png'
                        };
                        this.settings.setUserSetting('name', user.nome);
                        this.settings.setUserSetting('job', user.funcao + ' - ' + user.departamento);
                        this.settings.setUserSetting('picture', 'assets/img/user/default.png');
                    });

                    setTimeout(() => {
                        this.asyncOp = false;
                        this.route.navigateByUrl('/');
                    }, 300);
                }, (error) => {
                    // toast?
                    console.log(error);
                    this.asyncOp = false;
                    this.errorMessage = error;
                });
        }
    }

    ngOnInit() {

    }

}
