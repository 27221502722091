import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { RoutesService } from "../../routes/routes.service";
import { FiltroMultiplex } from "../../modelo/filtromultiplex";

@Component({
  selector: 'filtro-localidade',
  templateUrl: './localidade.component.html',
  styleUrls: ['./localidade.component.scss']
})
export class LocalidadeComponent implements OnInit, ControlValueAccessor {

  tagCidadesValue;
  tagCidadesOptions;
  tagCinemasValue;
  tagCinemasOptions;

  /**
     * @description id atribuido a div pai
     * @name objectId
     * @type {string}
     */
  @Input() public objectId: string = "localidade-filtro";

  /**
     * @description função que será chamada ao clique do botão Aplicar
     * @name filtroModel
     * @type {FiltroMultiplex}
     */
  @Input() public filtro: FiltroMultiplex;

  changeCallback: (item: FiltroMultiplex) => void;
  touchCallback: () => void;

  constructor(private server: RoutesService) { }

  ngOnInit() {
    this.fillCidades();
    this.fillCinemas();
  }

  private limparCidades() {
    this.tagCidadesValue = [];
    this.filtro.cidades = null;
  }

  private limparCinemas() {
    this.tagCinemasValue = [];
    this.filtro.cinemas = null;
  }

  public get filtroObj(): FiltroMultiplex {
    return this.filtro;
  }

  public writeValue(items: FiltroMultiplex) {
    this.filtro = items || new FiltroMultiplex();
  }

  public registerOnChange(fn: any) {
    this.changeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.touchCallback = fn;
  }

  filtrarEmpresa() {
    this.fillCidades();
    this.fillCinemas();
  }

  filtrarCidades(ev) {
    console.log("[filtrarCidades] val EV: " + ev);
    this.aplicarTags();

    this.fillCinemas();
  }

  alterarCinemas(ev) {
    console.log("[alterarCinemas] val EV: " + ev);
    this.aplicarTags();
  }

  private fillCinemas() {

    let query: string = '';

    if (this.filtro.empresa) {
      query += 'empresa=' + this.filtro.empresa + '&';
    }

    if (this.filtro.cidades) {
      query += 'cidades=' + this.filtro.cidades + '&';
    }

    query += this.queryTimestamp();

    this.server.getDataFromService('dashboard/autocompletar/cinemas?' + query,
      (data) => {
        this.limparCinemas();

        this.tagCinemasOptions = data;
      });
  }

  private fillCidades() {

    let query: string = '';

    if (this.filtro.empresa) {
      query += 'empresa=' + this.filtro.empresa + '&';
    }

    query += this.queryTimestamp();

    this.server.getDataFromService('dashboard/autocompletar/cidades?' + query,
      (data) => {
        this.limparCidades();
        this.limparCinemas();

        this.tagCidadesOptions = data;
      });
  }

  private queryTimestamp(): string {
    return 'cache=false&timestamp=' + new Date().getTime();
  }

  private tagToValue(tag) {

    return tag.value != null ? tag.value + ',' : '';
  }

  private aplicarTags() {

    if (this.tagCidadesValue != null) {

      let cid = '';
      this.tagCidadesValue.forEach(element => {
        cid += this.tagToValue(element);
      });

      if (cid.length > 0) {
        this.filtro.cidades = cid.substr(0, cid.length - 1);
      } else {
        this.filtro.cidades = null
      }
    } else {
      this.filtro.cidades = null
    }

    if (this.tagCinemasValue != null) {

      let cin = '';
      this.tagCinemasValue.forEach(element => {
        cin += this.tagToValue(element);
      });

      if (cin.length > 0) {
        this.filtro.cinemas = cin.substr(0, cin.length - 1);
      } else {
        this.filtro.cinemas = null;
      }
    } else {
      this.filtro.cinemas = null;
    }
  }
}
