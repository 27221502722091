import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FiltroMultiplex } from '../../modelo/filtromultiplex';

@Component({
  selector: 'filtro-periodo',
  templateUrl: './periodo.component.html',
  styleUrls: ['./periodo.component.scss']
})
export class PeriodoComponent implements OnInit, ControlValueAccessor {

  periodo: string = "";
  dataInicio: string = new Date((new Date().getTime()) - (1000 * 60 * 60 * 24 * 7)).toISOString().substr(0, 10);
  dataTermino: string = new Date().toISOString().substr(0, 10);

  datasDisabled: boolean;

  changeCallback: (item: FiltroMultiplex) => void;
  touchCallback: () => void;

  /**
     * @description id atribuido a div pai
     * @name objectId
     * @type {string}
     */
  @Input() public objectId: string = "periodo-filtro";

  /**
     * @description função que será chamada ao clique do botão Aplicar
     * @name filtroModel
     * @type {FiltroMultiplex}
     */
  @Input() public filtro: FiltroMultiplex;

  constructor() { }

  ngOnInit() {
    this.filtro.dataInicio = this.dataInicio;
    this.filtro.dataTermino = this.dataTermino;
    this.filtro.periodo = '';
  }

  setPeriodo() {
    this.filtro.periodo = this.periodo;

    this.datasDisabled = this.periodo.length > 0;
    const today = new Date();

    switch (this.filtro.periodo) {
      case 'YTD': {
        const inicio = new Date(today.getFullYear(), 0, 1);
        this.dataInicio = inicio.toISOString().split('T')[0];
        this.dataTermino = new Date().toISOString().split('T')[0];

        this.filtro.dataInicio = this.dataInicio;
        this.filtro.dataTermino = this.dataTermino;
      }
        break;
      case 'MTD': {
        const inicio = new Date(today.getFullYear(), today.getMonth(), 1);
        this.dataInicio = inicio.toISOString().split('T')[0];
        this.dataTermino = new Date().toISOString().split('T')[0];

        this.filtro.dataInicio = this.dataInicio;
        this.filtro.dataTermino = this.dataTermino;
      }
        break;
      case 'WTD': {
        const day1 = new Date();
        const delta = (day1.getDay() < 4 )? (-7 + 4 - day1.getDay()) : (day1.getDay() - 4);
        const inicio = new Date( day1.getTime() + delta * 24 * 3600 * 1000);
        this.dataInicio = inicio.toISOString().split('T')[0];
        this.dataTermino = new Date().toISOString().split('T')[0];

        this.filtro.dataInicio = this.dataInicio;
        this.filtro.dataTermino = this.dataTermino;
      }
        break;
    }
  }

  setDateFrom() {
    this.filtro.dataInicio = this.dataInicio;
  }

  setDateTo() {
    this.filtro.dataTermino = this.dataTermino;
  }

  public get filtroObj(): FiltroMultiplex {
    return this.filtro;
  }

  public writeValue(item: FiltroMultiplex) {
    this.filtro = item || new FiltroMultiplex();
  }

  public registerOnChange(fn: () => void) {
    this.changeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.touchCallback = fn;
  }
}
