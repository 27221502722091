import { Perfil } from '../../perfil/perfil';

export class User {
    id: number;
    nome: string;
    sobrenome: string;
    email: string;
    funcao: string;
    departamento: string;
    ativo: boolean;
    perfil: Perfil;
}
