'use strict';

export const INICIO_DOS_TEMPOS = '2000-01-01';
export const DATA_SERVER = '/api/';
// export const DATA_SERVER = 'http://admin.cinesystem.site/api/';
export const COOKIE_LOGIN_NAME = 'autorizacaoUsoBackoffice';
export const COOKIE_DOMAIN_PATH = '';
export const VERSION = '0.1';
export const USER_KEY = 'CP_BACKOFFICE_USER_CODE';
export const COOKIE_PERMS_NAME = 'user_perms';

